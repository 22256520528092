<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container">
            <div class="row mt-4">
                <div class="col-12 col-md-4">
                    <h2 class="h2 mb-2">Login</h2>
                    <b-alert v-model="is_login_error" variant="danger" dismissible>
                        Invalid email or password.
                    </b-alert>
                    <b-form autocomplete="off" @submit.prevent="login" action="POST">
                        <b-form-group
                        label="Email"
                        label-for="email"
                        >
                            <b-form-input
                            id="email"
                            v-model="email"
                            type="email" 
                            required
                            placeholder="Enter email"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                        label="Password"
                        label-for="password"
                        >
                            <b-form-input
                            id="password"
                            v-model="password"
                            type="password"
                            required
                            placeholder="Enter password"
                            ></b-form-input>
                        </b-form-group>
                        <b-button type="submit" variant="primary">Login</b-button>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import navAdmin from './../components/nav-admin';
import { userType } from './../mixins/user-type.mixin';

export default {
    data() {
        return {
            email: '',
            password: '',
            is_login_error: false
        }
    },
    mixins:[userType],
    methods: {
        login() {
            var app = this;
            this.$auth.login({
                params: {
                    email: app.email,
                    password: app.password
                },
                success() {
                    
                    if(app.$site_started_from_url && app.$site_started_from_url !== 'admin') {
                         const directionUrl = app.$site_started_from_url;
                         app.$site_started_from_url = null;
                         location.href = directionUrl;
                    }

                    if(app.$auth.user().role == app.editor.id) {
                        app.$router.push({ name: 'Orders' });
                        return;
                    }

                    app.$router.push({ name: 'admin' });
                },
                error() {
                    app.is_login_error = true
                },
                rememberMe: true,
                fetchUser: true
            });
        }
    },
    components: {
        navAdmin
    }
}
</script>